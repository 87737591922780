import React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../../style-utilities/variables";

const StyledResultsStats = styled.div`
  margin-bottom: 4em;
  .stats-right {
    margin-top: 2em;
    .stats {
      text-align: center;
      h3 {
        font-size: 48px;
        margin-bottom: 0.2em;
      }
    }
  }

  .stats:last-child() {
    border-bottom: none;
  }

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: center;
    .image-left {
      width: 70%;
    }
    .stats-right {
      width: 30%;
      .stats {
        text-align: left;
        max-width: 210px;
      }
    }
  }
`;

const CaseStudyStats = props => {
  return (
    <StyledResultsStats>
      <div className="image-left">
        <img src={props.image} />
      </div>
      <div className="stats-right">
        {props.stats.map((item, idx) => {
          return item.result_heading ? (
            <div
              className={`${
                idx === props.stats.length - 1 ? "stats" : "stats bottom-line"
              }`}
              key={"stats-" + idx}
              id={"stat-" + (idx + 1)}
            >
              <h3>{item.result_heading}</h3>
              <p>{item.result_sub_heading}</p>
            </div>
          ) : (
            <p>{item.result_sub_heading}</p>
          );
        })}
      </div>
    </StyledResultsStats>
  );
};

export default CaseStudyStats;
