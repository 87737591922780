import React, { Component } from 'react';
import { Link, graphql } from 'gatsby';
import { breakpoints, fonts, colors } from '../style-utilities/variables';

//style:
import styled, { consolidateStreamedStyles } from 'styled-components';

//components
import ContactBanner from '../components/ContactBanner';
import NavBar from '../components/NavBar';
import CaseStudyStats from '../components/CaseStudies/SingleCaseStudy/CaseStudyStats';
import CaseStudyQuotation from '../components/CaseStudies/SingleCaseStudy/CaseStudyQuotation';
import CircleSection from '../components/CircleSection';
import Related3Grid from '../components/Related/Related3Grid';
import SEO from '../components/SEO';

// THE VIEW
const StyledCaseStudyPage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  .title {
    font-size: 48px;
    text-transform: none;
  }

  @media (min-width: ${breakpoints.desktop}) {
    .title {
      max-width: 90%;
    }
  }

  .moreCaseStudies {
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: ${colors.secondaryGray};
    padding: 0rem 2rem 2rem 2rem;
    border-top: 1px solid #cfd0d1;
    border-bottom: 1px solid #cfd0d1;

    & > h2 {
      font-size: 22px;
      margin: 2rem auto;
      @media (min-width: ${breakpoints.mobileMax}) {
        font-size: 28px;
      }
    }
    .image-box,
    .noImage-box {
      height: 50%;
      margin-bottom: 0;
    }
    .item-content {
      height: 50%;
      padding: 1rem;
    }
    #seeAllCaseStudies {
      margin: 30px auto 0rem auto;
      width: 18rem;
    }
  }

  .related-container {
    display: grid;
    background-color: ${colors.secondaryGray};
    padding: 2rem 3rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 1.5rem;
    @media (min-width: ${breakpoints.tablet}) {
      width: 100%;
    }
  }
  .reach-out {
    margin: 0 auto;
    max-width: 1500px;
    padding: 2rem 1.875rem;
    & > div {
      margin: auto;
      max-width: 1440px;
    }
    @media (min-width: ${breakpoints.tablet}) {
      padding: 3rem 1.875rem;
    }
  }
`;

/* // THE PAGE: */
class CaseStudyTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
      Preview Mode -------------------------------------
      */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/case_study/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*[]
      -------------------------------------------------
      */
  }

  render() {
    const previewData = this.state.previewPayload;
    let post = this.props.data.wordpressWpCaseStudy;
    let topics = this.props.data.allWordpressWpTopic.edges;
    let solutions = this.props.data.allWordpressWpOurSolutions.edges;
    let technologies = this.props.data.allWordpressWpTechnology.edges;
    let posts = this.props.data.allWordpressWpCaseStudy;
    let verticals = this.props.data.allWordpressWpVertical.edges;
    let filteredVerticals = [],
      filteredSolutions = [],
      filteredTechnology = [],
      filteredTopics = [];

    if (this.state.isPreviewMode) {
      post.title = previewData.title.rendered;
      post.content = previewData.content.rendered;
      post.acf = previewData.acf;
    }

    // Generate list of topics for this post
    if (post.topic) {
      post.topic.forEach(function(item) {
        topics.forEach(function(o) {
          if (item === o.node.wordpress_id) {
            filteredTopics.push(o.node);
          }
        });
      });
    }

    // Generate list of verticals for this post
    if (post.vertical) {
      post.vertical.forEach(function(item) {
        verticals.forEach(function(o) {
          if (item === o.node.wordpress_id) {
            filteredVerticals.push(o.node);
          }
        });
      });
    }

    // Generate list of solutions for this post
    post.our_solutions.forEach(function(item) {
      solutions.forEach(function(o) {
        if (item === o.node.wordpress_id) {
          filteredSolutions.push(o.node);
        }
      });
    });

    // Generate list of services for this post
    post.technology.forEach(function(item) {
      technologies.forEach(function(o) {
        if (item === o.node.wordpress_id) {
          filteredTechnology.push(o.node);
        }
      });
    });

    /* filter the posts to get rid of do not delete dummy and slug */
    let nodes = posts.edges;
    /* filter for those that are NOT the current case study: */
    let filteredPosts = nodes.slice(0, 5).filter(post => {
      return post.node.slug !== post.slug && post.node.slug !== 'do-not-delete';
    });

    //matches this case study with eligible content based on topics, services, verticals, tech

    let removeDuplicates = arr => {
      const final = [];
      arr.map((e, i) => !final.includes(e) && final.push(e));
      return final;
    };

    let postList = filteredPosts.filter(function(e) {
      return e.node.slug !== post.slug;
    });

    let relatedPostItems = [];

    postList.filter(e => {
      e.node.topic.filter(d => {
        post.topic.map((topic, i) => {
          if (topic === d) {
            relatedPostItems.push(e.node);
          }
        });
      });
    });

    postList.filter(e => {
      e.node.our_solutions.filter(d => {
        post.our_solutions.map((solution, i) => {
          if (solution === d) {
            relatedPostItems.push(e.node);
          }
        });
      });
    });

    postList.filter(e => {
      e.node.vertical.filter(d => {
        post.vertical.map((vertical, i) => {
          if (vertical === d) {
            relatedPostItems.push(e.node);
          }
        });
      });
    });

    postList.filter(e => {
      e.node.technology.filter(d => {
        post.technology.map((technology, i) => {
          if (technology === d) {
            relatedPostItems.push(e.node);
          }
        });
      });
    });

    postList.map((post, index) => {
      relatedPostItems.push(postList[index].node);
    });

    relatedPostItems = removeDuplicates(relatedPostItems);

    return (
      <StyledCaseStudyPage>
        <SEO
          isBlogPost={true}
          postData={post}
          postImage={post.better_featured_image.source_url}
        />
        <NavBar link="results/case-studies" name="Case Studies" />
        <h1 className="title page-container">{post.title}</h1>

        <CircleSection
          caseStudy={true}
          background={post.better_featured_image.source_url}
          client={post.acf.client}
          underCircle={post.acf.challenge_copy}
          logo={post.acf.client_logo.source_url}
          client={post.acf.client}
          topics={filteredTopics}
          verticals={filteredVerticals}
          services={filteredSolutions}
          technology={filteredTechnology}
        />
        <div className="page-container">
          <CaseStudyStats
            stats={post.acf.results}
            image={post.acf.results_image.source_url}
          />
          <CaseStudyQuotation
            quote={post.acf.quote_copy}
            source={post.acf.quote_author}
            solution={post.acf.solution_copy}
          />
          <Related3Grid
            header="Read More Results"
            items={relatedPostItems}
            readAll="See All Case Studies"
            readAllLink="/results/case-studies/"
            gridCardType="minimal"
          />
          <ContactBanner
            headline={post.acf.contact_headline}
            cta={post.acf.contact_cta}
            url={post.acf.contact_url}
          />
        </div>
      </StyledCaseStudyPage>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpCaseStudy(slug: { eq: $slug }) {
      title
      topic
      type
      our_solutions
      vertical
      technology
      slug
      better_featured_image {
        source_url
        alt_text
      }
      acf {
        seo_title
        seo_canonical
        seo_description
        quote_copy
        quote_author
        challenge_copy
        solution_copy
        featured_case_study
        contact_headline
        contact_cta
        contact_url
        client
        client_logo {
          source_url
        }
        results {
          result_heading
          result_sub_heading
        }
        results_image {
          source_url
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressWpVertical {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressWpTechnology {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressWpCaseStudy(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          slug
          type
          topic
          our_solutions
          vertical
          technology
          date(formatString: "MMMM D, YYYY")
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            client
            client_logo {
              source_url
            }
            excerpt
          }
        }
      }
    }
  }
`;

export default CaseStudyTemplate;
