import React from 'react';
import styled from 'styled-components';
import { breakpoints, fonts, colors } from '../../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';

const StyledQuotation = styled.div`
  .testament {
    color: black;
    font-weight: bold;
    size: 22px;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.3;
  }
  .name {
    font-size: 16px;
    padding-bottom: 1em;
    margin-bottom: 5em;
  }
  .subHead {
    font-size: 22px;
  }
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    .left-quote {
      width: 80%;
      margin-right: 30px;
    }
    .right-solution {
      width: 100%;
      max-width: 650px;
      p {
        max-width: 586px;
      }
    }
  }
`;
const StyledNoQuotation = styled.div`
  width: 100%;
  padding: 0.1rem;
`;

const CaseStudyQuotation = props => {
  return props.quote ? (
    <StyledQuotation>
      <div className="left-quote">
        <p className="testament">{props.quote}</p>
        <p className="name bottom-line">— {props.source}</p>
        <div className="right-solution">
          <h3>Solution</h3>
          <div class="subHead">{ReactHtmlParser(props.solution)}</div>
        </div>
      </div>
    </StyledQuotation>
  ) : (
    <StyledNoQuotation>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="right-solution col-md-7">
          <h3>Solution</h3>
          <div class="subHead">{ReactHtmlParser(props.solution)}</div>
        </div>
      </div>
    </StyledNoQuotation>
  );
};

export default CaseStudyQuotation;
